import Vue from 'vue'
import CoreuiVuePro from '@coreui/vue-pro'
import App from './App.vue'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import rendererUtil from './js/common/rendererUtil.js'
import fileMngUtil from './js/common/fileMngUtil.js'
import commonUtil from './js/common/commonUtil.js'
import LoadingBar from '@/views/common/LoadingBar'
import dayjs from 'dayjs'


Vue.use(rendererUtil)
Vue.use(fileMngUtil)
Vue.use(commonUtil)
Vue.component("loadingBar",LoadingBar)
Vue.config.productionTip = false
Vue.config.performance = true
Vue.use(CoreuiVuePro)
Vue.prototype.$log = console.log.bind(console);
//Vue.prototype.$axios = axios
Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs
    }
  }
})
new Vue({
  router,
  store,
  icons,
  render: h => h(App)
}).$mount('#app')
