import {nAxios} from '@/api/index'

const Statistics = {
    namespaced: true,
    state:{
        statsStartDate: '',
        statsEndDate: '',
        statsAppKey: '',
        statsPackageNm: null,
        statsAppVersion: null,
        statsOsType: null,
        statsStoreYn: null,
        statsTotalData:[],
        statsDailyByDateCntList:[],
        statsMonthlyByDateCntList: [],
        statsTotalByDateCnt: [],
        
        // 카운트 통계
        statsTotalCnt:{}, // 통계 총건수 정보(총 접속건수, 정상접속건수, 이상탐지건수, 검증실패건수)
        statsMonthlyCntList:[], // 월별 총건수 목록        
        statsMonthlyConnectCntList:[], // 월별 총 접속 건수
        statsMonthlyNormalCntList:[], // 월별 정상접속 건수
        statsMonthlyTamperDetectCntList:[], // 월별 이상탐지 건수
        statsMonthlyFailCntList:[], // 월별 검증실패 건수
        statsMonthlyLabelList:[], // 월별 레이블

        // 통계 상세현황 목록
        statsDetailList:[],
        statsDetailTotalCnt:0,
    },
    getters:{
        getStatsStartDate(state){
            return state.statsStartDate
        },
        getStatsEndDate(state){
            return state.statsEndDate
        },
        getStatsAppKey(state){
            return state.statsAppKey
        },
        getStatsPackageNm(state){
            return state.statsPackageNm    
        },
        getStatsAppVersion(state){
            return state.statsAppVersion
        },
        getStatsOsType(state){
            return state.statsOsType
        },
        getStatsStoreYn(state){
            return state.statsStoreYn
        },
        getStatsDetailList(state){
            return state.statsDetailList
        },
        getStatsDetailTotalCnt(state){
            return state.statsDetailTotalCnt
        },
    },
    mutations:{
        setStatsStartDate(state, data){
            state.statsStartDate = data || ''
        },
        setStatsEndDate(state, data){
            state.statsEndDate = data || ''
        },
        setStatsAppKey(state, data){
            state.statsAppKey = data || null
        },
        setStatsPackageNm(state, data){
            state.statsPackageNm = data || null
        },
        setStatsAppVersion(state, data){
            state.statsAppVersion = data || null
        },
        setStatsOsType(state, data){
            state.statsOsType = data || null
        },
        setStatsStoreYn(state, data){
            state.statsStoreYn = data || null
        },
        setStatsTotalData(state, data){
            state.statsTotalData = data || []
        },
        setStatsDailyByDateCntList(state, data){
            state.statsDailyByDateCntList = data || []
        },
        setStatsMonthlyByDateCntList(state, data){
            state.statsMonthlyByDateCntList = data || []
        },
        setStatsMonthlyCntList(state, data){
            state.statsMonthlyCntList= data||[]
            state.statsMonthlyConnectCntList=[]
            state.statsMonthlyNormalCntList=[]
            state.statsMonthlyTamperDetectCntList=[]
            state.statsMonthlyFailCntList=[]
            state.statsMonthlyLabelList=[]
            for(var index in state.statsMonthlyCntList){
                var item = state.statsMonthlyCntList[index]
                state.statsMonthlyConnectCntList.push(item.connectCnt)
                state.statsMonthlyNormalCntList.push(item.normalCnt)
                state.statsMonthlyTamperDetectCntList.push(item.tamperDetectCnt)
                state.statsMonthlyFailCntList.push(item.failCnt)
                let regYearMonth = item.regYearMonth+''
                state.statsMonthlyLabelList.push(regYearMonth.substr(0,4)+'-'+regYearMonth.substr(4))
            }
        },
        setStatsTotalByDateCnt(state, data){
            state.statsTotalByDateCnt = data || []
        },
        setStatsTotalCnt(state, data){
            state.statsTotalCnt = data || []
        },
        setStatsDetailList(state, data){
            if(data['_embedded'])
                state.statsDetailList = data._embedded.validationLogList
            else 
                state.statsDetailList = []
        },
        setStatsDetailTotalCnt(state, data){
            state.statsDetailTotalCnt = data || 0
        },
    },
    actions:{
        getStatistics(context){
            let payload = {
                startDate : context.getters.getStatsStartDate,
                endDate : context.getters.getStatsEndDate,
                packageNm: context.getters.getStatsPackageNm,
                appVersion: context.getters.getStatsAppVersion,
                osType: context.getters.getStatsOsType,
                storeYn: context.getters.getStatsStoreYn
                //appKey: context.getters.getStatsAppKey
            }
                
            return new Promise((resolve, reject) => { 
                nAxios.get('/api/statis', {params: payload})
                    .then(response => {
                        context.commit('setStatsTotalData', response.data)
                        context.commit('setStatsDailyByDateCntList', response.data['statsDailyByDateCntList'])
                        context.commit('setStatsMonthlyByDateCntList', response.data['statsMonthlyByDateCntList'])
                        context.commit('setStatsMonthlyCntList', response.data['statsMonthlyCntList'])
                        context.commit('setStatsTotalByDateCnt', response.data['statsTotalByDateCnt'])
                        context.commit('setStatsTotalCnt', response.data['statsTotalCnt'])
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },      
        getStatsDetailList(context, payload){
            return new Promise((resolve, reject) => { 
                nAxios.get("/api/statis/details", {params: payload})
                    .then(response => {
                        context.commit("setStatsDetailList", response.data)
                        context.commit("setStatsDetailTotalCnt", response.headers["x-total-count"])
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
    }
}

export default Statistics