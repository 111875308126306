import {nAxios} from '@/api/index'

const LogMngInfo = {
    namespaced:true,
    state: {
        logMngInfoList:[],
    },
    getters:{
    },
    mutations:{
        setLogMngInfoList(state, data){
            state.logMngInfoList = data
        },
    },
    actions:{
        getLogMngInfoList(context){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/config/log-mng-infos")
                    .then(response => {
                        context.commit("setLogMngInfoList", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        modifyLogMngInfoList(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post('/api/config/update/log-mng-infos', payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
    }
}

export default LogMngInfo
