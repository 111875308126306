<template>
  <div :style="outerContainerStyles" class="loading">
    <div :style="centeringStyles">
      <slot>
        <h1 class="d-inline">Loading... </h1><CSpinner size="5xl" color="success"/>
      </slot>
    </div>
  </div>
</template>

<script>
import CSpinner from '@coreui/vue-pro/src/components/spinner/CSpinner'

export default {
  name: 'LoadingBar',
  props: {
  },
  components: {
    CSpinner
  },
  data () {
    return {
      containerCoords: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      },
      parentCoords: null
    }
  },
  mounted () {
    if (this.boundaries) {
      this.setOffsets()
    }
  },
  computed: {
    outerContainerStyles () {
      return {
        ...this.containerCoords,
        position: 'absolute',
        'background-color': this.$store.state.darkMode?`rgb(42, 43, 54, 0.8)`:`rgb(255,255,255, 0.8)`
      }
    },
    centeringStyles () {
      return {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)'
      }
    }
  },
  methods: {
  }
}
</script>