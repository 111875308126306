import appObfuscation from "@/store/modules/appObfuscation";

export default{
	install(Vue) {
        Vue.prototype.$excelDownload = common.excelDownload
        Vue.prototype.$getExcelColumns = common.getExcelColumns
        Vue.prototype.$downloadApp = common.downloadApp
        Vue.prototype.$downloadObfuscationApp = common.downloadObfuscationApp
    }
}

export const common = {
    // 엑셀 다운로드
    async excelDownload(payload) {
      // _.merge - Lodash 라이브러리
      //   let reqHeaders = store.state.api.common // 공통적으로 사용하는 헤더정보
      //   _.merge(reqHeaders, requestObj.headers) // 요청 시 설정한 헤더 override
      try {
        await this.$store.dispatch('common/downloadExcel', payload)
        common.downloadFileResponse(this.$store.state.common.excelResponse)
        //requestObj.callback(this.$store.state.common.excelResponse.data)

      } catch(error){
        console.log(error)
        alert("엑셀 다운로드가 실패하였습니다.")
      }
    },

    getFileName(contentDisposition) {
      let fileName = contentDisposition
        .split(';')
        .filter((ele) => {
          return ele.indexOf('filename') > -1
        })
        .map((ele) => {
          return ele
            .replace(/"/g, '')
            .split('=')[1]
        })
      return fileName[0] ? fileName[0] : null
    },

    getExcelColumns(fields){
      var columns = {};
      fields.forEach(function (field) {
        if(field.label != null && field.label !="")
          columns[field.key]=field.label
      });
      return columns
    } ,

    downloadFileResponse(res){
      try {
        let blob = new Blob([res.data], { type: res.headers['content-type'] })
        console.log(res.headers['content-disposition']);
        let fileName = common.getFileName(res.headers['content-disposition']) 
        console.log(fileName);
        fileName = decodeURI(fileName) // 파일명 디코딩 (프로젝트에 따라 사용여부 옵션)
        if (window.navigator.msSaveOrOpenBlob) { // IE 10+
          console.log(fileName);
          window.navigator.msSaveOrOpenBlob(blob, fileName)
        } else { // not IE
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.target = '_self'
          console.log(fileName);
          if (fileName) link.download = fileName
          link.click()
        }
      } catch (e) {
        console.error(e)
      }
    },

    // 앱 다운로드
    async downloadApp(payload){
      try {
        await this.$store.dispatch('appInfo/downloadApp', payload)
        common.downloadFileResponse(this.$store.state.appInfo.appFile)
      } catch(error){
        if(error.response.status == '404')
          alert("앱파일이 존재하지 않습니다.")
        else
          alert("다운로드 실패하였습니다.")
      }
    },

    async downloadObfuscationApp(payload){
      try {
        await this.$store.dispatch('appObfuscation/downloadApp', payload)
        common.downloadFileResponse(this.$store.state.appObfuscation.appFile)
      } catch(error){
        if(error.response.status == '404')
          alert("앱파일이 존재하지 않습니다.")
        else
          alert("다운로드 실패하였습니다.")
      }
    },
  }
