import {nAxios} from '@/api/index'

const Common = {
    namespaced:true,
    state: {
        excelResponse:[], // 엑셀다운로드 응답
    },
    getters:{
    },
    mutations:{
        setExcelResponse(state, data){
            state.excelResponse = data || []
        },
    },
    actions:{
        // 엑셀다운로드
        downloadExcel(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post("/api/common/excel", payload, {responseType: 'arraybuffer'})
                    .then(response => {
                        console.log(response)
                        context.commit("setExcelResponse", response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
    }
}

export default Common