import {nAxios} from '@/api/index'

const License = {
    namespaced:true,
    state: {
        licenseInfo: {},
    },
    getters:{
    },
    mutations:{
        setLicenseInfo(state,data){
            state.licenseInfo = data || {}
        }
    },
    actions:{
        getLicenseInfo(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/license")
                    .then(response => {
                        context.commit("setLicenseInfo", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
    }
}

export default License