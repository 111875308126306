import {nAxios} from '@/api/index'

const AppObfuscation = {
    namespaced:true,
    state: {
        appObfuscationList:[],
        appObfuscationListTotalCnt:0,
        appObfuscation:[],
        appFileInfo:{},
        appFile:{},
        appPolicyAuditLogList:[],
        appPolicyAuditLogListTotalCnt:[],
        // appCertData:'',
        appAllList: [],
    },
    getters:{
    },
    mutations:{
        setAppObfuscationList(state, data){
            if(data['_embedded'])
                state.appObfuscationList = data._embedded.appObfuscationList
            else
                state.appObfuscationList = []
        },
        setAppObfuscationListTotalCnt(state, data){
            state.appObfuscationListTotalCnt = data || 0
        },
        setAppObfuscation(state, data){
            state.appObfuscation = data || []
        },
        setAppFileInfo(state, data){
            state.appFileInfo = data || []
        },
        setAppFile(state, data){
            state.appFile = data || []
        },
        setAppPolicyAuditLogList(state, data){
            if(data['_embedded'])
                state.appPolicyAuditLogList = data._embedded.appPolicyAuditLogList
            else
                state.appPolicyAuditLogList = []
        },
        setAppPolicyAuditLogListTotalCnt(state, data){
            state.appPolicyAuditLogListTotalCnt = data || 0
        },
        // setAppCertData(state, data){
        //     state.appCertData = data || ''
        // },
        setAppAllList(state, data){
            if(data['_embedded'])
                state.appAllList = data._embedded.appObfuscationList
            else
                state.appObfuscationList = []
        }
    },
    actions:{
        getAppObfuscationList(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/obfuscation", {params: payload})
                    .then(response => {
                        context.commit("setAppObfuscationList", response.data)
                        context.commit("setAppObfuscationListTotalCnt", response.headers["x-total-count"])
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAppObfuscation(context, payload){
            return new Promise((resolve, reject) => {
                console.log(payload)
                nAxios.get("/api/obfuscation/"+payload)
                    .then(response => {
                        console.log(response)
                        context.commit("setAppObfuscation", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAppFileInfo(context, payload, headers){
            return new Promise((resolve, reject) => {
                nAxios.post("/api/obfuscation/app-file/info", payload, headers)
                    .then(response => {
                        console.log(response)
                        context.commit("setAppFileInfo", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        registerAppObfuscation(context, payload, header){
            return new Promise((resolve, reject)=> {
                nAxios.post("/api/obfuscation", payload, header)
                    .then(response => {
                        console.log(response)
                        context.commit("setAppObfuscation", response.data)
                        resolve(response)
                    })
                    .catch(error=>{
                        reject(error)
                    })
            })
        },
        // modifyAppObfuscation(context, payload){
        //     return new Promise((resolve, reject) => {
        //         nAxios.post('/api/obfuscation/update/'+payload.appKey, payload)
        //             .then(response => {
        //                 console.log(response)
        //                 resolve(response)
        //             })
        //             .catch(error => {
        //                 console.log(error)
        //                 reject(error)
        //             })
        //     })
        // },
        deleteAppObfuscation(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post("/api/obfuscation/delete/"+payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        // modifyAppPolicyInfo(contextm, payload){
        //     return new Promise((resolve, reject) => {
        //         nAxios.post("/api/obfuscation/"+payload.appKeyStr+"/policies", payload)
        //             .then(response => {
        //                 console.log(response)
        //                 resolve(response)
        //             })
        //             .catch(error => {
        //                 console.log(error)
        //                 reject(error)
        //             })
        //     })
        // },
        downloadApp(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/obfuscation/app-file/"+payload, {responseType: 'arraybuffer'})
                    .then(response => {
                        console.log(response)
                        context.commit("setAppFile", response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        // getAppPolicyAuditLogList(context, payload){
        //     return new Promise((resolve, reject) => {
        //         nAxios.get('/api/obfuscation/'+payload.appKey+'/policies/audit-log', {params: payload})
        //             .then(response => {
        //                 context.commit("setAppPolicyAuditLogList", response.data)
        //                 context.commit("setAppPolicyAuditLogListTotalCnt", response.headers["x-total-count"])
        //                 resolve(response)
        //             })
        //             .catch(error => {
        //                 console.log(error)
        //                 reject(error)
        //             })
        //     })
        // },
        // getAppCertData(context, packageNm){
        //     return new Promise((resolve, reject) => {
        //         nAxios.get('/api/obfuscation/'+packageNm+'/certData')
        //             .then(response => {
        //                 context.commit("setAppCertData", response.data)
        //                 resolve(response)
        //             })
        //             .catch(error => {
        //                 console.log(error)
        //                 reject(error)
        //             })
        //     })
        // },
        getAppAllList(context){
            return new Promise((resolve, reject) => {
                nAxios.get('/api/obfuscation/list')
                    .then(response => {
                        context.commit("setAppAllList", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        // resetCertData(context, payload){
        //     console.log(payload)
        //     console.log(payload.appKey)
        //
        //     return new Promise((resolve, reject) => {
        //         nAxios.post("/api/obfuscation/cert/"+payload.appKey, payload)
        //             .then(response => {
        //                 console.log(response)
        //                 resolve(response)
        //             })
        //             .catch(error => {
        //                 console.log(error)
        //                 reject(error)
        //             })
        //     })
        // },
    }
}

export default AppObfuscation
