import {nAxios} from '@/api/index'

const AppInfo = {
    namespaced:true,
    state: {
        appInfoList:[],
        appInfoListTotalCnt:0,
        appInfo:[],
        appFileInfo:{},
        appFile:{},
        appPolicyAuditLogList:[],
        appPolicyAuditLogListTotalCnt:[],
        appCertData:'',
        appAllList: [],
    },
    getters:{
    },
    mutations:{
        setAppInfoList(state, data){
            if(data['_embedded'])
                state.appInfoList = data._embedded.appInfoList
            else
                state.appInfoList = []
        },
        setAppInfoListTotalCnt(state, data){
            state.appInfoListTotalCnt = data || 0
        },
        setAppInfo(state, data){
            state.appInfo = data || []
        },
        setAppFileInfo(state, data){
            state.appFileInfo = data || []
        },
        setAppFile(state, data){
            state.appFile = data || []
        },
        setAppPolicyAuditLogList(state, data){
            if(data['_embedded'])
                state.appPolicyAuditLogList = data._embedded.appPolicyAuditLogList
            else
                state.appPolicyAuditLogList = []
        },
        setAppPolicyAuditLogListTotalCnt(state, data){
            state.appPolicyAuditLogListTotalCnt = data || 0
        },
        setAppCertData(state, data){
            state.appCertData = data || ''
        },
        setAppAllList(state, data){
            if(data['_embedded'])
                state.appAllList = data._embedded.appInfoList
            else
                state.appInfoList = []
        }
    },
    actions:{
        getAppInfoList(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/apps", {params: payload})
                    .then(response => {
                        context.commit("setAppInfoList", response.data)
                        context.commit("setAppInfoListTotalCnt", response.headers["x-total-count"])
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAppInfo(context, payload){
            return new Promise((resolve, reject) => {
                console.log(payload)
                nAxios.get("/api/apps/"+payload)
                    .then(response => {
                        console.log(response)
                        context.commit("setAppInfo", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAppFileInfo(context, payload, headers){
            return new Promise((resolve, reject) => {
                nAxios.post("/api/apps/app-file/info", payload, headers)
                    .then(response => {
                        console.log(response)
                        context.commit("setAppFileInfo", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        registerAppInfo(context, payload, header){
            return new Promise((resolve, reject)=> {
                nAxios.post("/api/apps", payload, header)
                    .then(response => {
                        console.log(response)
                        context.commit("setAppInfo", response.data)
                        resolve(response)
                    })
                    .catch(error=>{
                        reject(error)
                    })
            })
        },
        modifyAppInfo(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post('/api/apps/update/'+payload.appKey, payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        deleteAppInfo(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post("/api/apps/delete/"+payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        modifyAppPolicyInfo(contextm, payload){
            return new Promise((resolve, reject) => {
                nAxios.post("/api/apps/"+payload.appKeyStr+"/policies", payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        downloadApp(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/apps/app-file/"+payload, {responseType: 'arraybuffer'})
                    .then(response => {
                        console.log(response)
                        context.commit("setAppFile", response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAppPolicyAuditLogList(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get('/api/apps/'+payload.appKey+'/policies/audit-log', {params: payload})
                    .then(response => {
                        context.commit("setAppPolicyAuditLogList", response.data)
                        context.commit("setAppPolicyAuditLogListTotalCnt", response.headers["x-total-count"])
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAppCertData(context, packageNm){
            return new Promise((resolve, reject) => {
                nAxios.get('/api/apps/'+packageNm+'/certData')
                    .then(response => {
                        context.commit("setAppCertData", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAppAllList(context){
            return new Promise((resolve, reject) => {
                nAxios.get('/api/apps/list')
                    .then(response => {
                        context.commit("setAppAllList", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        resetCertData(context, payload){
            console.log(payload)
            console.log(payload.appKey)

            return new Promise((resolve, reject) => {
                nAxios.post("/api/apps/cert/"+payload.appKey, payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
    }
}

export default AppInfo
