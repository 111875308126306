import axios from 'axios'
import router from '../router';

function callRegenToken(){
        return new Promise((resolve, reject) => {
                nAxios.post("/api/refreshToken")
                        .then(res => {
                                localStorage.setItem('Token', res.data.accessToken);
                                localStorage.setItem('ExpireTime', res.data.accessExpireTime);
                                resolve(res.data)
                        })
                        .catch(err => {
                                reject(err)
                        });
                }
        );
}

export function createInstance () {
        let nAxios = axios.create ({
            headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin":"*"
            }
            //ContextPath 설정시 검색 으로 모든 내용 수정후 빌드 필요
            // ,baseURL: '/appiron'
        })

        //Request interceptor for API calls
        nAxios.interceptors.request.use(
                async config => {

                        const accessToken = localStorage.getItem("Token");
                        if(accessToken != null){
                                config.headers = {
                                        'Authorization': `Bearer ${accessToken}`,
                                        'Accept': 'application/json',
                                        'Access-Control-Allow-Origin':'*'
                                }
                        }
                        return config;
                },
                error => {
                        Promise.reject(error)
                }
        );

        // Response interceptor for API calls
        nAxios.interceptors.response.use((response) => {
                return response
        }, async function (error) {
                const originalRequest = error.config;

                //토큰 만료
                if(error.response.status == 401 && error.response.data == 'tokenExpired'){
                        alert("세션이 만료 되었습니다.");
                        window.location.reload();
                        return;
                        // return Promise.reject(error);
                }


                //토큰 만료 직전인경우
                if (error.response.status == 409 && !originalRequest._retry
                        && error.response.data == 'tokenExpiredSoon') {
                        originalRequest._retry = true;

                        try{
                                //accessToken 재발급요청
                                await callRegenToken();
                        }catch(err){

                                alert("세션이 만료 되었습니다.");
                                //로그인 페이지로 이동
                                window.location.reload();
                                return;
                        }
                        return nAxios(originalRequest);
                }

                //에러페이지 설정
                //잘못된 요청 혹은 오류일경우
                if(error.response.status == 400) {
                        alert("잘못된 요청 입니다.");
                        router.push('/error/400')
                        // return Promise.reject(error);
                }

                //500 에러
                if(error.response.status == 500){
                        router.push('/error/500')
                }

                //404 에러
                if(error.response.status == 404){
                        router.push('/error/404')
                }

                return Promise.reject(error);
        }
        );
        return nAxios;

}
export const nAxios = createInstance()

