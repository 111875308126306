import loginStore from '@/store/modules/loginStore'

export default{
	install(Vue) {
        Vue.prototype.$isEmpty = function(value){
            if(value == undefined) return true
            else if(!value) return true
            else return false
        },
        Vue.prototype.$response = function(error){
            if(error.response) {       
                if(error.response.status >=400 && error.response.status <500)
                    alert(error.response.data.errorMessage)
                else if(error.response.status >=500 && error.response.status <600)
                    alert("서버와 통신할 수 없습니다.")
                else
                    alert("실패하였습니다."+ error.response.data.errorMessage)
            }
        },
        Vue.prototype.$hasWritePermission = function(menuKey){
            if(menuKey == undefined || menuKey == null) return true 

            let menuWritePermissionList = loginStore.state.menuWritePermissionList;
            return menuWritePermissionList.includes(String(menuKey))
        },
        Vue.prototype.$isEqual = function(value1, value2){
            if(value1==null) value1 = ""
            if(value2==null) value2 = ""
            if(value1==value2) return true
            else false
        },
        Vue.prototype.$isSuperAdmin = function(){
            if(loginStore.state.adminType == '0') return true
            else return false
        }
    }
}