export default {
    namespaced: true,
    state: {
        // 앱정책감사로그 화면
        appPolicyAuditLogList_appKey: "",
        // 앱상세화면
        appInfo_appKey: "",
        appInfo_mode : "",
        // 계정상세화면
        adminInfo_adminKey: "",
        adminInfo_mode : "",
    },
    mutations: {
        appPolicyAuditLogList_appKey: (state, value) =>
        value ? (state.appPolicyAuditLogList_appKey = value) : (state.appPolicyAuditLogList_appKey = ""),
        appInfo_appKey: (state, value) =>
        value ? (state.appInfo_appKey = value) : (state.appInfo_appKey = ""),
        appInfo_mode: (state, value) =>
        value ? (state.appInfo_mode = value) : (state.appInfo_mode = ""),
        adminInfo_adminKey: (state, value) =>
        value ? (state.adminInfo_adminKey = value) : (state.adminInfo_adminKey = ""),
        adminInfo_mode: (state, value) =>
        value ? (state.adminInfo_mode = value) : (state.adminInfo_mode = ""),
    },
  };