import {nAxios} from '@/api/index'

const PermissionGroup = {
    namespaced:true,
    state: {
        permissionGroupList:[],
        totalCnt:0,
        permissionGroupInfo:[],
        menuPermissionInfo:[],
        beforeAppPermissionGroupInfo:[],
    },
    getters:{
    },
    mutations:{
        setPermissionGroupList(state, data){
            if(data['_embedded'])
                state.permissionGroupList = data._embedded.permissionGroupList
            else
                state.permissionGroupList = []
        },
        setTotalCount(state, data){
            state.totalCnt = data || 0
        },
        setPermissionGroupInfo(state, data){
            state.permissionGroupInfo = data || []
        },
        setMenuPermissionInfo(state, data){
            state.menuPermissionInfo = data || []
        },
        setMenuInfo(state, data){
            state.menuInfo = data || []
        },
        checkedAllMenu(state, allChecked){
            let checkedVal = '0'
            allChecked ? checkedVal ='1' : '0'
            for(let i in state.menuPermissionInfo){
                for(let j in state.menuPermissionInfo[i]['childrenItems']){
                    if(state.menuPermissionInfo[i]['childrenItems'][j].menuKey != "102"){
                        state.menuPermissionInfo[i]['childrenItems'][j].readYn = checkedVal
                        if(state.menuPermissionInfo[i]['childrenItems'][j].pmenuKey != "101"){
                            state.menuPermissionInfo[i]['childrenItems'][j].writeYn = checkedVal
                        }
                    }
                }
            }
        },
        checkedMenu(state, target){
            let checkedVal = '0'
            target.checked ? checkedVal = '1' :'0'
            for(let i in state.menuPermissionInfo){
                for(let j in state.menuPermissionInfo[i]['childrenItems']){
                    if(state.menuPermissionInfo[i]['childrenItems'][j].menuKey == target.id){
                        state.menuPermissionInfo[i]['childrenItems'][j][target.value] = checkedVal
                    }
                }
            }
        }
    },
    actions:{
        getPermissionGroupList(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get('/api/permissionGroup', {params: payload})
                    .then(response => {
                        context.commit('setPermissionGroupList', response.data)
                        context.commit('setTotalCount', response.headers["x-total-count"])
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getPermissionGroupInfo(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get('/api/permissionGroup/'+payload)
                    .then(response => {
                        context.commit('setPermissionGroupInfo', response.data)
                        context.commit('setMenuPermissionInfo', response.data.menuPermissionInfoList)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        registerPermissionGroup(context, payload){
            return new Promise((resolve, reject)=> {
                nAxios.post('/api/permissionGroup/', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error=>{
                        reject(error)
                    })
            })
        },
        getMenuPermissionInfo(context, payload){
            let url ='/api/permissionGroup/menuInfo'
            if(payload) url += "/"+payload
            return new Promise((resolve, reject) => {
                nAxios.get(url)
                    .then(response => {
                        context.commit('setMenuPermissionInfo', response.data)
                        resolve(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        modifyPermissionGroup(contextm, payload){
            return new Promise((resolve, reject) => {
                nAxios.post('/api/permissionGroup/update/'+payload.groupKey, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        deletePermissionGroup(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post('/api/permissionGroup/delete/'+payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        }
    }
}

export default PermissionGroup
