import {nAxios} from '@/api/index'

const AppInfo = {
    namespaced:true,
    state: {
        coreJarVersion: null
    },
    getters:{
    },
    mutations:{
        setCoreJarVersion(state, data){
            state.coreJarVersion = data.coreVersion
        }
    },
    actions:{
        getSysConfig(context, payload){
            return new Promise((resolve, reject) => { 
                nAxios.get("/api/config/core-jar", {params: payload})
                    .then(response => {
                        context.commit("setCoreJarVersion", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        }
    }
}

export default AppInfo