import {nAxios} from '@/api/index'

const Dashboard = {
    namespaced:true,
    state: {
        refreshEvent:null,
    },
    getters:{
    },
    mutations:{
        setRefreshEvent(state, data){
            state.refreshEvent = data || null
        },
    },
    actions:{
        setRefreshEvent(context, data){
            context.commit("setRefreshEvent", data)
        },
    }
}

export default Dashboard