import {nAxios} from '@/api/index'

//로그인 처리 관련 저장소 모듈
const loginStore = {
    namespaced: true,
    state: {
      id : null,
      isAuthenticated: false,
      adminKey: null,
      adminType: null,
      menuReadPermissionList: [],
      menuWritePermissionList: [],
      allAppPermissionYn: false,
      appPermissionList: [],
      fisrtTimeLoginYn: false,
      pubKey:null,
    },
    mutations: {
      updateLoginData(state, payload) {
        state.adminKey = payload.adminKey
        state.adminType = payload.adminType
        state.menuReadPermissionList = payload.menuReadPermissionList
        state.menuWritePermissionList = payload.menuWritePermissionList
        state.appPermissionList= payload.appPermissionList
        state.allAppPermissionYn = payload.allAppPermissionYn
        state.fisrtTimeLoginYn = payload.fisrtTimeLoginYn
      },
      logout (state) {
        state.id = null
        state.isAuthenticated = null
        state.adminKey = null
        state.adminType = null
        state.permissionList = []
        state.menuReadPermissionList = []
        state.menuWritePermissionList = []
        state.allAppPermissionYn = false
        state.appPermissionList = []
        state.isLogin = false
        localStorage.removeItem("Token")
        state.fisrtTimeLoginYn = false
      },
      updateLoginStatus(state, payload) {
        state.id = payload
        state.isLogin = true
      },
      setPubKey(state, payload){
        state.pubKey = payload
      }
    },
    getters: {
    },
    actions: {
      login({ commit }, payload) {
        return new Promise((resolve, reject) => {
          nAxios.post("/api/login", payload)
            .then(res => {
              commit('updateLoginStatus', payload.id)
              commit('updateLoginData', res.data)
              localStorage.setItem('ExpireTime', res.data.accessExpireTime)
              localStorage.setItem('Token',res.data.accessToken)
              localStorage.setItem('LoginId',payload.id)
              resolve(res)
            })
            .catch(err => {
              reject(err.response.data)
            });
        })
      },
      logout({commit}) {
        if(window.confirm("로그아웃 하시겠습니까?")){
          return new Promise((resolve, reject) => {
            nAxios.post("/api/logout")
              .then(res => {
                commit('logout')
                location.reload();
              })
              .catch(err => {
                reject(err.message)
              });
          })
        }
      },
      getRsaPubKey(context){
        return new Promise((resolve, reject) => {
          nAxios.post("/api/security/rsaPubKey")
            .then(response => {
              context.commit('setPubKey', response.data);
              resolve(response)
            })
        }).catch(err => {
          reject(err)
        })
      },
      token({ commit }, payload) {
        return new Promise((resolve, reject) => {
          nAxios.post("/api/token", payload)
            .then(res => {
              commit('updateLoginStatus', payload.adminId)
              commit('updateLoginData', res.data)
              resolve(res)
            })
            .catch(err => {
              reject(err.response.data)
            });
        })
      }
    }
}

export default loginStore
