import {nAxios} from '@/api/index'

const AuditLog = {
    namespaced:true,
    state: {
        auditLogList:[],
        totalCnt:0,
        auditLogInfo: {},
    },
    getters:{
    },
    mutations:{
        setAuditLogList(state, data){
            if(data['_embedded'])
                state.auditLogList = data._embedded.auditLogList
            else 
                state.auditLogList = []
        },
        setTotalCount(state, data){
            state.totalCnt = data || 0
        },
        setAuditLogInfo(state,data){
            state.auditLogInfo = data || {}
        }
    },
    actions:{
        getAuditLogList(context, payload){
            return new Promise((resolve, reject) => { 
                nAxios.get("/api/audits", {params: payload})
                    .then(response => {
                        context.commit("setAuditLogList", response.data)
                        context.commit("setTotalCount", response.headers["x-total-count"])
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAuditLogInfo(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/audits/"+payload)
                    .then(response => {
                        context.commit("setAuditLogInfo", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
    }
}

export default AuditLog