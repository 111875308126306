import Vue from 'vue'
import Vuex from 'vuex'
import permissionGroup from './modules/permissionGroup'
import loginStore from './modules/loginStore'
import appInfo from './modules/appInfo'
import appObfuscation from './modules/appObfuscation'
import common from './modules/common'
import auditLog from './modules/auditLog'
import validationLog from './modules/validationLog'
import createPersistedState from 'vuex-persistedstate'
import dataStore from './modules/dataStore'
import sysConfig from './modules/sysConfig'
import adminInfo from './modules/adminInfo'
import license from './modules/license'
import statistics from './modules/statistics'
import dashboard from './modules/dashboard'
import logMngInfo from './modules/logMngInfo'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  darkMode: true
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  }
}

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    loginStore,
    permissionGroup,
    appInfo,
    appObfuscation,
    common,
    auditLog,
    validationLog,
    dataStore,
    sysConfig,
    adminInfo,
    license,
    statistics,
    dashboard,
    logMngInfo,
  },
  plugins: [
    createPersistedState({
      paths: ['dataStore']
    })
  ]
})
