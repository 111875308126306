import {nAxios} from '@/api/index'

const AdminInfo = {
    namespaced:true,
    state: {
        adminInfoList:[],
        adminInfoListTotalCnt:0,
        adminInfo:[],
    },
    getters:{
    },
    mutations:{
        setAdminInfoList(state, data){
            if(data['_embedded'])
                state.adminInfoList = data._embedded.adminInfoList
            else
                state.adminInfoList = []
        },
        setAdminInfoListTotalCnt(state, data){
            state.adminInfoListTotalCnt = data || 0
        },
        setAdminInfo(state, data){
            state.adminInfo = data || []
        },
    },
    actions:{
        getAdminInfoList(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/accounts", {params: payload})
                    .then(response => {
                        context.commit("setAdminInfoList", response.data)
                        context.commit("setAdminInfoListTotalCnt", response.headers["x-total-count"])
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getAdminInfo(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/accounts/"+payload)
                    .then(response => {
                        console.log(response)
                        context.commit("setAdminInfo", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        registerAdminInfo(context, payload, header){
            return new Promise((resolve, reject)=> {
                nAxios.post("/api/accounts", payload, header)
                    .then(response => {
                        console.log(response)
                        context.commit("setAdminInfo", response.data)
                        resolve(response)
                    })
                    .catch(error=>{
                        reject(error)
                    })
            })
        },
        modifyAdminInfo(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post('/api/accounts/update/'+payload.adminKey, payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        deleteAdminInfo(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post("/api/accounts/delete/"+payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        modifyAdminPswd(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post('/api/accounts/'+payload.adminKey, payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        getMyAccount(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.get("/api/accounts/myaccount/"+payload)
                    .then(response => {
                        console.log(response)
                        context.commit("setAdminInfo", response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        modifyMyAccount(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post('/api/accounts/update/myaccount/'+payload.adminKey, payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        modifyMyAccountPswd(context, payload){
            return new Promise((resolve, reject) => {
                nAxios.post('/api/accounts/myaccount/'+payload.adminKey, payload)
                    .then(response => {
                        console.log(response)
                        resolve(response)
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
    }
}

export default AdminInfo
