import dayjs from 'dayjs'
const rendererCodeList = {
    appPolicyAuditEvent: [{value:'0', label:'등록', color: 'success', icon: ''},
                      {value:'1', label:'수정', color: 'warning', icon: ''},
                      //{value:'2', label:'삭제', color: 'secondary', icon: ''}
                    ],
    osType: [{value:'0', label:'Android', color: '', icon: 'IconAndroid.svg'},
                {value:'1', label:'iOS', color: '', icon: 'IconApple.svg'}],
    storeYn: [{value:'0', label:'미사용', color: '', icon: ''},
                {value:'1', label:'사용', color: '', icon: ''}],
    logType: [  {value:'', label:'항목을 선택하세요.'},
                {value:'1', label:'인증', color: '', icon: ''},
                {value:'2', label:'대시보드', color: '', icon: ''},
                {value:'3', label:'위변조 검증 목록', color: '', icon: ''},
                {value:'4', label:'감사 로그', color: '', icon: ''},
                {value:'5', label:'앱 관리', color: '', icon: ''},
                {value:'6', label:'앱 정 책감사로그', color: '', icon: ''},
                {value:'7', label:'권한 그룹관리', color: '', icon: ''},
                {value:'8', label:'계정관리', color: '', icon: ''},
                {value:'9', label:'내 정보', color: '', icon: ''},
                {value:'10', label:'설정', color: '', icon: ''}
            ],
    logResult: [{value:'0', label:'실패', color: 'warning', icon: ''},
                {value:'1', label:'성공', color: 'success', icon: ''}],
    logEvent: [ {value:'', label:'이벤트를 선택하세요.'},
                {value:'101', label:'로그인', color: '', icon: '', parent: '1'},
                {value:'102', label:'로그아웃', color: '', icon: '', parent: '1'},
                //{value:'201', label:'대시보드 조회', color: '', icon: '', parent: '2'},
                {value:'202', label:'대시보드 상세조회', color: '', icon: '', parent: '2'},
                //{value:'203', label:'대시보드 프린트', color: '', icon: '', parent: '2'},
                {value:'204', label:'대시보드 상세조회 엑셀다운로드', color: '', icon: '', parent: '2'},
                //{value:'205', label:'대시보드 상세조회 프린트', color: '', icon: '', parent: '2'},
                {value:'301', label:'위변조 검증 목록 조회', color: '', icon: '', parent: '3'},
                {value:'302', label:'위변조 검증 목록 상세조회', color: '', icon: '', parent: '3'},
                {value:'303', label:'위변조 검증 목록 엑셀다운로드', color: '', icon: '', parent: '3'},
                //{value:'401', label:'감사로그 조회', color: '', icon: '', parent: '4'},
                //{value:'402', label:'감사로그 상세조회', color: '', icon: '', parent: '4'},
                {value:'403', label:'감사 로그 엑셀다운로드', color: '', icon: '', parent: '4'},
                //{value:'404', label:'감사로그 프린트', color: '', icon: '', parent: '4'},
                {value:'501', label:'앱 목록 조회', color: '', icon: '', parent: '5'},
                {value:'502', label:'앱 상세조회', color: '', icon: '', parent: '5'},
                {value:'503', label:'앱 등록', color: '', icon: '', parent: '5'},
                {value:'504', label:'앱 수정', color: '', icon: '', parent: '5'},
                {value:'505', label:'앱 삭제', color: '', icon: '', parent: '5'},
                {value:'506', label:'앱 정책 수정', color: '', icon: '', parent: '5'},
                {value:'507', label:'앱 목록 엑셀다운로드', color: '', icon: '', parent: '5'},
                {value:'508', label:'앱 다운로드', color: '', icon: '', parent: '5'},
                {value:'509', label:'앱 난독화 목록 조회', color: '', icon: '', parent: '5'},
                {value:'510', label:'앱 난독화 상세조회', color: '', icon: '', parent: '5'},
                {value:'511', label:'앱 난독화 등록', color: '', icon: '', parent: '5'},
                {value:'512', label:'앱 난독화 수정', color: '', icon: '', parent: '5'},
                {value:'513', label:'앱 난독화 삭제', color: '', icon: '', parent: '5'},
                {value:'514', label:'앱 난독화 목록 엑셀다운로드', color: '', icon: '', parent: '5'},
                {value:'515', label:'난독화 앱 다운로드', color: '', icon: '', parent: '5'},
                {value:'601', label:'앱 정책 감사 로그 조회', color: '', icon: '', parent: '6'},
                {value:'602', label:'앱 정책 감사 로그 엑셀다운로드', color: '', icon: '', parent: '6'},
                //{value:'603', label:'앱정책감사로그 프린트', color: '', icon: '', parent: '6'},
                {value:'701', label:'권한 그룹 목록 조회', color: '', icon: '', parent: '7'},
                {value:'702', label:'권한 그룹 상세조회', color: '', icon: '', parent: '7'},
                {value:'703', label:'권한 그룹 등록', color: '', icon: '', parent: '7'},
                {value:'704', label:'권한 그룹 수정', color: '', icon: '', parent: '7'},
                {value:'705', label:'권한 그룹 삭제', color: '', icon: '', parent: '7'},
                //{value:'706', label:'권한그룹 목록 엑셀다운로드', color: '', icon: '', parent: '7'},
                {value:'801', label:'계정 목록 조회', color: '', icon: '', parent: '8'},
                {value:'802', label:'계정 상세조회', color: '', icon: '', parent: '8'},
                {value:'803', label:'계정 등록', color: '', icon: '', parent: '8'},
                {value:'804', label:'계정 수정', color: '', icon: '', parent: '8'},
                {value:'805', label:'계정 삭제', color: '', icon: '', parent: '8'},
                {value:'806', label:'계정 비밀번호 변경', color: '', icon: '', parent: '8'},
                //{value:'807', label:'계정 목록 엑셀다운로드', color: '', icon: '', parent: '8'},
                {value:'901', label:'내 정보 조회', color: '', icon: '', parent: '9'},
                {value:'902', label:'내 정보 수정', color: '', icon: '', parent: '9'},
                {value:'903', label:'내 정보 비밀번호 변경', color: '', icon: '', parent: '9'},
                {value:'904', label:'내 정보 아이디 변경(슈퍼관리자)', color: '', icon: '', parent: '9'},
                {value:'1001', label:'로그성 데이터 관리 조회', color: '', icon: '', parent: '10'},
                {value:'1002', label:'로그성 데이터 관리 수정', color: '', icon: '', parent: '10'}
            ],
    tamperYn: [{value:'0', label:'정상', color: 'success', icon: ''},
                {value:'1', label:'변조', color: 'danger', icon: ''},
                {value:'2', label:'변조(OFF)', color: 'warning', icon: ''}],
    validationResult: [{value:'0', label:'실패', color: 'danger', icon: ''},
                {value:'1', label:'성공', color: 'success', icon: ''}],
    ipYn: [{value:'0', label:'NO', color: 'warning', icon: ''},
                {value:'1', label:'YES', color: 'success', icon: ''}],
    adminType: [{value:'0', label:'슈퍼관리자', color: 'success', icon: ''},
                {value:'1', label:'부관리자', color: 'warning', icon: ''}],
    detectYn: [{value:'0', label:'OFF', color: 'secondary', icon: ''},
                {value:'1', label:'ON', color: 'primary', icon: ''}],
    logMngPeriod: [{value:13, label:'1주', color: '', icon: ''},
                {value:14, label:'2주', color: '', icon: ''},
                {value:15, label:'3주', color: '', icon: ''},
                {value:1, label:'1개월', color: '', icon: ''},
                {value:2, label:'2개월', color: '', icon: ''},
                {value:3, label:'3개월', color: '', icon: ''},
                {value:4, label:'4개월', color: '', icon: ''},
                {value:5, label:'5개월', color: '', icon: ''},
                {value:6, label:'6개월', color: '', icon: ''},
                {value:7, label:'7개월', color: '', icon: ''},
                {value:8, label:'8개월', color: '', icon: ''},
                {value:9, label:'9개월', color: '', icon: ''},
                {value:10, label:'10개월', color: '', icon: ''},
                {value:11, label:'11개월', color: '', icon: ''},
                {value:12, label:'1년', color: '', icon: ''}],
}

export default{
	install(Vue) {
        Vue.prototype.$renderer = common.renderer
        Vue.prototype.$convertDate = common.convertDate
        Vue.prototype.$convertDateDay = common.convertDateDay
        Vue.prototype.$getCodeList = common.getCodeList
    }
}

export const common = {
    renderer:(column, type, value)=>{
        let codeList = rendererCodeList[column]
        let result = value
        switch(type){
            case 0: // label
                for(var i in codeList){
                    if(codeList[i].value == value){
                        result = codeList[i].label
                        break
                    }
                }
                break
            case 1: // badge color
                result = 'primary' // 배지 디폴트 색상
                for(var i in codeList){
                    if(codeList[i].value == value){
                        result = codeList[i].color
                        break
                    }
                }
                break
            case 2: // icon
                for(var i in codeList){
                    if(codeList[i].value == value){
                        result = require('@/assets/icons/'+codeList[i].icon)
                    }
                }
                break
            default:
                break
        }
        return result
    },
    convertDate:(dateStr)=>{
        if(dateStr==null) return ""

        const date = dayjs(dateStr)
        if(isNaN(date)) return String(dateStr)
        // else return date.format('YYYY년 MM월 DD일 HH시 mm분') // '2021년 09월 02일 18시 00분'
        else return date.format('YYYY-MM-DD HH:mm:ss') // '2021-09-02 18:00:10'
    },
    convertDateDay:(dateStr)=>{
        if(dateStr==null) return ""

        const date = dayjs(dateStr)
        if(isNaN(date)) return String(dateStr)
        else return date.format('YYYY년 MM월 DD일') // '2021년 09월 02일'
    },
    getCodeList:(column)=>{
        return rendererCodeList[column]
    }
}
