import Vue from 'vue'
import VueRouter from 'vue-router'
import loginStore from '@/store/modules/loginStore'
import dashboard from '@/store/modules/dashboard'

// Containers
const Login = () => import('@/views/auth/Login')
const TheContainer = () => import('@/containers/TheContainer')
const Dashboard = () => import('@/views/Dashboard')
const ValidationLogList = () => import('@/views/monitoring/validationLog/ValidationLogList')
const AuditLogList = () => import('@/views/monitoring/audit/AuditLogList')
const AppList = () => import('@/views/app/appMng/AppList')
const AppObfuscation = () => import('@/views/app/appMng/AppObfuscation')
const RegistApp = () => import('@/views/app/appMng/RegistApp')
const RegistObfuscation = () => import('@/views/app/appMng/RegistObfuscation')
const AppInfo = () => import('@/views/app/appMng/AppInfo')
const AppPolicyAuditLogList = () => import('@/views/app/appMng/AppPolicyAuditLogList')
const PermissionGroupList = () => import('@/views/admin/permissionGroup/PermissionGroupList')
const RegistPermissionGroup = () => import('@/views/admin/permissionGroup/RegistPermissionGroup')
const PermissionGroupInfo = () => import('@/views/admin/permissionGroup/PermissionGroupInfo')
const AccountList = () => import('@/views/admin/account/AccountList')
const RegistAccount = () => import('@/views/admin/account/RegistAccount')
const AccountInfo = () => import('@/views/admin/account/AccountInfo')
const MyAccountInfo = () => import('@/views/admin/account/MyAccountInfo')
const LicenseInfo = () => import('@/views/license/LicenseInfoModal')
const LogMngInfo = () => import('@/views/setting/LogMngInfo')
const InitPasswordChange = () => import('@/views/admin/account/InitPasswordChange')
const Error400 = () => import('@/views/error/Error400')
const Error404 = () => import('@/views/error/Error404')
const Error500 = () => import('@/views/error/Error500')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/dashboard',
    name: '홈',
    component: TheContainer,
    children:[
      {
        path: 'dashboard',
        name: 'Dashboard',
        component:Dashboard,
        meta: {
          title: '대시보드'
        }
      },
      {
        path: 'myAccountInfo',
        name: 'MyAccountInfo',
        component: MyAccountInfo,
        meta: {
          title: '내 정보'
        }
      },
      {
        path: 'licenseInfo',
        name: 'LicenseInfo',
        component: LicenseInfo,
        meta: {
          title: '라이선스 정보'
        }
      },
      {
        path: 'logMngInfo',
        name: 'LogMngInfo',
        component: LogMngInfo,
        meta: {
          title: '로그성 데이터 관리'
        }
      },
      {
        path: 'monitoring',
        redirect: '/monitoring/validationLog/validationLogList',
        name: 'Monitoring',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'validationLog/validationLogList',
            name: 'ValidationLogList',
            component: ValidationLogList,
            meta: {
              title: '위변조 검증 목록'
            }
          },
          {
            path: 'audit/auditLogList',
            name: 'AuditLogiList',
            component: AuditLogList,
            meta: {
              title: '감사 로그'
            }
          }
        ],
        meta :{
          title: '모니터링'
        }

      },
      {
        path: 'app',
        redirect: '/app/appMng/appList',
        name: 'AppMng',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'appMng/appList',
            name: 'AppList',
            component: AppList,
            meta: {
              title: '앱 관리'
            }
          },
          {
            path: 'appMng/registApp',
            name: 'RegistApp',
            component: RegistApp,
            meta: {
              title: '앱 등록'
            }
          },
          {
            path: 'appMng/appInfo',
            name: 'AppInfo',
            component: AppInfo,
            meta: {
              title: '앱 정보'
            },
            props: true
          },
          {
            path: 'appMng/registObfuscation',
            name: 'RegistObfuscation',
            component: RegistObfuscation,
            meta: {
              title: '앱 등록'
            }
          },
          {
            path: 'appMng/appObfuscation',
            name: 'AppObfuscation',
            component: AppObfuscation,
            meta: {
              title: '앱 난독화'
            },
            props: true
          },
          {
            path: 'appMng/appPolicyAuditLogList',
            name: 'AppPolicyAuditLogList',
            component: AppPolicyAuditLogList,
            meta: {
              title: '앱 정책 감사로그'
            },
            props: true
          }
        ],
        meta: {
          title: '앱 관리'
        }
      },
      {
        path: 'admin',
        redirect: '/admin/permissionGroup/permissionGroupList',
        name: 'AdminAccount',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'permissionGroup/permissionGroupList',
            name: 'PermissionGroupList',
            component: PermissionGroupList,
            meta: {
              title: '권한 그룹관리'
            }
          },
          {
            path: 'permissionGroup/registPermissionGroup',
            name: 'RegistPermissionGroup',
            component: RegistPermissionGroup,
            meta: {
              title: '권한 그룹등록'
            }
          },
          {
            path: 'permissionGroup/permissionGroupInfo/:num',
            name: 'PermissionGroupInfo',
            component: PermissionGroupInfo,
            meta: {
              title: '권한 그룹상세'
            }
          },
          {
            path: 'account/accountList',
            name: 'AccountList',
            component: AccountList,
            meta: {
              title: '계정관리'
            }
          },
          {
            path: 'account/registAccount',
            name: 'RegistAccount',
            component: RegistAccount,
            meta: {
              title: '계정등록'
            }
          },
          {
            path: 'account/accountInfo',
            name: 'AccountInfo',
            component: AccountInfo,
            meta: {
              title: '계정정보'
            }
          }
        ],
        meta: {
          title : '관리자 계정'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/initPasswordChange',
    name: 'InitPasswordChange',
    component:InitPasswordChange
  },
  {
    path: '/error',
      redirect: '/error/404',
      name: 'Error',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '400',
          name: 'Error400',
          component: Error400
        },
        {
          path: '404',
          name: 'Error404',
          component: Error404
        },
        {
          path: '500',
          name: 'Error500',
          component: Error500
        }
      ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  // 대시보드 자동새로고침 제거
  window.clearInterval(dashboard.state.refreshEvent);

  let accessToken = localStorage.getItem("Token");
  let isLogin = loginStore.state.isLogin;
  //로그인페이지거나 로그인 한경우에만 통과
  if( to.path == '/login' || (accessToken != null && isLogin)){
    next();
    return
  }

  //로그인 아닌 상태에서 다른 url 접근시 로그인페이지로 이동
  if (!isLogin || accessToken == null) {
    next({ path: '/login' });
    return
  }
  next();
});

export default router
